import Swiper from 'swiper/bundle';

window.addEventListener('load', () => {
  const swiper = new Swiper(".konintodoke-top-carousel", {
    loop: true, // ループ有効
    slidesPerView: 2, // 一度に表示する枚数
    speed: 6000, // ループの時間
    allowTouchMove: false, // スワイプ無効
    spaceBetween: 20,
    autoplay: {
      delay: 0, // 途切れなくループ
      disableOnInteraction: false,
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 768px
      768: {
        slidesPerView: 3,
      }
    }
  });
  const swiper2 = new Swiper(".konintodoke-top-carousel-reverse", {
    loop: true, // ループ有効
    slidesPerView: 2, // 一度に表示する枚数
    speed: 6000, // ループの時間
    allowTouchMove: false, // スワイプ無効
    spaceBetween: 20,
    autoplay: {
      delay: 0, // 途切れなくループ
      disableOnInteraction: false,
      reverseDirection: true, // 逆方向有効化
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      }
    }
  });
})
