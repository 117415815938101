window.addEventListener('load', () => {
  const rankingIcons = Array.from(document.getElementsByClassName('konintodoke-top-certificate-ranking-icon'))
  rankingIcons.forEach((el, index) => {
    switch (index){
      case 0:
        rankingIcons[index].classList.add('-first-place')
        break
      case 1:
        rankingIcons[index].classList.add('-second-place')
        break
      case 2:
        rankingIcons[index].classList.add('-third-place')
        break
      default:
        el.textContent = index + 1
        rankingIcons[index].classList.add('-other-than-top3')
        break
    }
  })
})
