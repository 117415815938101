import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css'
window.addEventListener('load', () => {
  const swiper = new Swiper('.swiper-container', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  });
})
