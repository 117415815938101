window.addEventListener('load', () => {
  const elements = Array.from(document.querySelectorAll('[data-detail-dialog]'))
  elements.forEach( element => {
    element.addEventListener('click', () => {
      const dailogId = element.getAttribute('data-detail-dialog')
      const dialog = `[data-dialog="${dailogId}"]`
      const dialogElement = document.querySelector(dialog)
      dialogElement.setAttribute('aria-hidden', "false")
    })
  } )

  const closeElements = Array.from(document.querySelectorAll('[data-detail-dialog-clsoe]'))
  closeElements.forEach( element => {
    element.addEventListener('click', () => {
      const closeDailogId = element.getAttribute('data-detail-dialog-clsoe')
      const closeDialog = `[data-dialog="${closeDailogId}"]`
      const closeDialogElement = document.querySelector(closeDialog)
      closeDialogElement.setAttribute('aria-hidden', "true")
    })
  } )
})
