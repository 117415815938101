window.addEventListener('load', () => {
  const button = document.querySelector('[data-action="dispay-all-certificate"]')
  const label = document.querySelector('[data-label="dispay-all-certificate"]')

  if(button) {
    button.addEventListener('click', () => {
      const target = document.querySelector('[data-target="dispay-all-certificate"]')
      const certificates = Array.from(target.querySelectorAll('[data-target-certificate="true"]'))
      certificates.forEach( (certificate) => {
        if(certificate.getAttribute('aria-hidden') === 'true'){
          certificate.setAttribute('aria-hidden', 'false')
          button.classList.add('-open')
          button.classList.remove('-close')
          label.innerText = '閉じる'
        }else{
          certificate.setAttribute('aria-hidden', 'true')
          button.classList.add('-close')
          button.classList.remove('-open')
          label.innerText = 'もっと見る'
        }
      })
    })
  }
})
