window.addEventListener('load', () => {
  const swipers = Array.from(document.querySelectorAll('[data-ui="swiper1"]'))
  if(swipers) {
    swipers.forEach(swiper => {
      if(swiper) {
        const items = swiper.querySelectorAll('[data-ui="swiper1-item"]')
        const pagination = swiper.querySelector('[data-ui="swiper1-pagination"]')
        const prevButton = swiper.querySelector('[data-ui="swiper-prev"]')
        const nextButton = swiper.querySelector('[data-ui="swiper-next"]')
        let currentIndex = 0

        // 現在地表示 の HTML生成
        if(pagination) {
          for(let i = 1; i <= items.length; i ++) {
            className = i === 1 ? 'swiper1__pagination-bullet -active':'swiper1__pagination-bullet'
            html = `<span class='${className}' tabindex='0' data-swiper1-bullet='${i-1}' role='button' aria-label='Go to slide ${i}'></span>`
            pagination.insertAdjacentHTML('beforeend', html)
          }
        }
        const bullets = swiper.querySelectorAll('[data-swiper1-bullet]')

        // ボタンの動作設定
        if(prevButton) {
          prevButton.addEventListener('click', () => {
            let index = currentIndex - 1
            currentIndex = (index >= 0 && items.length > index) ? index:0

            items[currentIndex].scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start'
            })
            setUpButton()
          })
        }

        if(nextButton) {
          nextButton.addEventListener('click', () => {
            let index = currentIndex + 1
            currentIndex = (index >= 0 && items.length > index) ? index:0

            items[currentIndex].scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start'
            })
            setUpButton()
          })
        }

        function setUpButton() {
          if(prevButton && currentIndex === 0){
            prevButton.setAttribute('aria-hidden', 'true')
          }else{
            prevButton.setAttribute('aria-hidden', 'false')
          }
          if(nextButton && currentIndex === (items.length - 1)){
            nextButton.setAttribute('aria-hidden', 'true')
          }else{
            nextButton.setAttribute('aria-hidden', 'false')
          }
        }
        setUpButton()

        // 要素の監視
        const options = {
          root: swiper,
          rootMargin: "0px -50%",
          threshold: 0
        }

        const observer = new IntersectionObserver(doWhenIntersect, options)
        items.forEach( (item, index) => {
          item.setAttribute('data-swiper1-item', index)
          item.setAttribute('id', `data-swiper1-item-${index}`)
          observer.observe(item)
        })

        function doWhenIntersect(entries) {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              let index = Number(entry.target.getAttribute('data-swiper1-item'))
              currentIndex = index

              // 現在地表示の変更
              bullets.forEach(bullet => {
                bullet.classList.remove('-active')
              })
              bullets.forEach(bullet => {
                if(bullet.getAttribute('data-swiper1-bullet') == index){
                  bullet.classList.add('-active')
                }
              })

              // 現在地によるボタンの変更
              setUpButton()
            }
          })
        }
      }
    })
  }
})
