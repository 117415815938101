window.addEventListener('load', () => {
  const button = document.querySelector('.global-any-header__menu-icon')
  const menu = document.querySelector('[data-hamburger="true"]')

  button?.addEventListener('click', () => {
    if(button.getAttribute('aria-expanded') === 'true' ) {
      menu.setAttribute('aria-expanded', 'false')
      button.setAttribute('aria-expanded', 'false')
    }else {
      menu.setAttribute('aria-expanded', 'true')
      button.setAttribute('aria-expanded', 'true')
    }
  })

  const hamburgerItems = Array.from(document.querySelectorAll('[data-hamburger-item="true"]'))
  hamburgerItems.forEach( hamburgerItem => {
    const buns = hamburgerItem.querySelector('[data-hamburger="buns"]')
    const inside = hamburgerItem.querySelector('[data-hamburger="inside"]')
    buns.addEventListener('click', () => {
      if(buns.getAttribute('aria-expanded') === 'false' ) {
        inside.setAttribute('aria-hidden', 'false')
        buns.setAttribute('aria-expanded', 'true')
      }else {
        inside.setAttribute('aria-hidden', 'true')
        buns.setAttribute('aria-expanded', 'false')
      }
    })
  })
})
