window.addEventListener('load', () => {
  const ankers = document.querySelectorAll('.konintodoke-printout-toc__link')
  if(ankers) {
    ankers.forEach((anker) => {
      anker.addEventListener('click', (event) => {
        event.preventDefault()
        const query = anker.href.split('#')
        if(query[1]) {
          const target = document.querySelector(`#${query[1]}`)
          target.scrollIntoView(true)

          const scrolledY = window.scrollY;
          if(scrolledY){
            window.scroll(0, scrolledY - 53);
          }
        }
      }, true)
    })
  }
})
