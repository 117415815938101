window.addEventListener('load', () => {
  const items = document.querySelectorAll('.costumes-top-segmented__item')
  const blocks = Array.from(document.querySelectorAll('.costumes-top-segmented-block'))

  if(items && blocks) {
    items.forEach( item => {
      item.addEventListener('click', () => {
        const label = item.getAttribute('data-segmented')
        const target = blocks.filter( block => block.getAttribute('data-segmented') === label)[0]

        items.forEach(i => {
          i.setAttribute('aria-selected', 'false')
        })
        item.setAttribute('aria-selected', 'true')

        blocks.forEach(block => {
          block.setAttribute('aria-hidden', 'true')
        })
        target.setAttribute('aria-hidden', 'false')
      })
    } )
  }
})
