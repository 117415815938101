window.addEventListener('DOMContentLoaded', () => {
  const targetFloating = document.querySelector('.js_floating-banner');
  const closeBtn = document.querySelector('.js_floating-banner-close');

  if (targetFloating && closeBtn) {
    closeBtn.addEventListener('click', () => {
      targetFloating.classList.remove('--active');
    });
    setTimeout(() => {
      targetFloating.classList.add('displayable');
    }, 1000)
  }
});
