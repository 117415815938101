window.addEventListener('load', () => {
  const mainImage = document.querySelector('[data-main-image]')
  if(mainImage) {
    const thumbnails = Array.from(document.querySelectorAll('[data-thumbnail-image]'))

    thumbnails.forEach( thumbnail => {
      thumbnail.addEventListener('click', () => {
        const path = thumbnail.getAttribute('data-thumbnail-image')
        mainImage.src = path
        thumbnails.forEach( thumbnail => {
          thumbnail.classList.remove('-selected')
          thumbnail.classList.add('-un-select')
        } )
        thumbnail.classList.add('-selected')
        thumbnail.classList.remove('-un-select')
      })
    } )
  }
})
