window.addEventListener('load', () => {
  const brands = document.querySelectorAll('.costumes-top-dress-brand')

  if(brands) {
    brands.forEach(brand => {
      const button = brand.querySelector('.costumes-top-dress-brand__button')
      const dialog = brand.querySelector('.costumes-top-dress-brand-modal-dialog')

      button.addEventListener('click', () => {
        dialog.setAttribute('aria-hidden', 'false')
      })

      dialog.addEventListener('click', () => {
        dialog.setAttribute('aria-hidden', 'true')
      })
    })
  }
})
